

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="card-title">รายชื่อลูกค้าที่ต้องการติดต่อ</h4> -->
            <div class="text-end">
              <button class="btn btn-primary" v-b-modal.modalCar>
                <i class="uil-search"></i> รายชื่อรถ</button
              >&nbsp;
              <button class="btn btn-primary" v-b-modal.modalContact>
                <i class="uil-search"></i> รายชื่อลูกค้า
              </button>
            </div>
            <form class="needs-validation" @submit.prevent="contactForm">
              <div class="row mt-3">
                <div class="col-sm-12 col-md-12 align-items-center">
                  <div class="row align-items-center">
                    <div class="col-md-4 col-sm-6">
                      <div class="mb-3 position-relative">
                        <code>* </code> <label for="branchId">สาขา:</label>
                        <multiselect
                          id="branchId"
                          v-model="contact.branchId"
                          :options="localDataBranchName"
                          :class="{
                            'is-invalid':
                              submitform && $v.contact.branchId.$error,
                          }"
                          track-by="nameTh"
                          label="nameTh"
                          :show-labels="false"
                        ></multiselect>

                        <div
                          v-if="submitform && $v.contact.branchId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.contact.branchId.required">{{
                            message
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-6">
                      <div class="mb-3 position-relative">
                        <code>* </code>
                        <label for="contactType">ประเภทการส่ง:</label>
                        <multiselect
                          id="contactType"
                          v-model="contact.contactType"
                          :options="optionContactType"
                          :class="{
                            'is-invalid':
                              submitform && $v.contact.contactType.$error,
                          }"
                          track-by="nameTh"
                          label="nameTh"
                          :show-labels="false"
                        ></multiselect>

                        <div
                          v-if="submitform && $v.contact.contactType.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.contact.contactType.required">{{
                            message
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-6">
                      <div class="mb-3 position-relative">
                        <code>* </code><label for="subject">เรื่อง:</label>
                        <div class="input-group">
                          <input
                            id="subject"
                            v-model="contact.subject"
                            class="form-control"
                            placeholder=""
                            :class="{
                              'is-invalid':
                                submitform && $v.contact.subject.$error,
                            }"
                          />
                          <div
                            v-if="submitform && $v.contact.subject.$error"
                            class="invalid-tooltip"
                          >
                            <span v-if="!$v.contact.subject.required">{{
                              message
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- <div class="col-md-3 col-sm-6">
                      <div class="mb-3 position-relative">
                        <label for="filterType">รูปแบบ</label>
                        <multiselect
                          id="filterType"
                          v-model="contact.filterType"
                          :options="optionFilterType"
                          :class="{
                            'is-invalid':
                              submitform && $v.contact.filterType.$error,
                          }"
                          track-by="name"
                          label="name"
                          :show-labels="false"
                        ></multiselect>

                        <div
                          v-if="submitform && $v.contact.filterType.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.contact.filterType.required">{{
                            message
                          }}</span>
                        </div>
                      </div>
                    </div> -->
                    <div class="col-md-4 col-sm-6">
                      <div class="mb-3 position-relative">
                        <code>* </code><label for="message">ข้อความ:</label>
                        <div class="input-group">
                          <textarea
                            id="message"
                            v-model="contact.message"
                            class="form-control"
                            placeholder=""
                            :class="{
                              'is-invalid':
                                submitform && $v.contact.message.$error,
                            }"
                          ></textarea>
                          <div
                            v-if="submitform && $v.contact.message.$error"
                            class="invalid-tooltip"
                          >
                            <span v-if="!$v.contact.message.required">{{
                              message
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-4"></div>
                    <div class="col-md-2 col-sm-3 text-end">
                      <b-button
                        class="btn btn ms-2 text-end"
                        variant="success"
                        type="submit"
                      >
                        <i class="uil-comment-alt-message"></i>
                        ส่งข้อความ
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="row mt-4">
               
                <div class="col-sm-12 col-md-12">
                  <div class="row align-items-center">
                    <div class="col-sm-12 col-md-3">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-block align-items-center">
                          แสดงผล
                          <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                            @input="handlePageChangeMess"
                          ></b-form-select
                          >&nbsp; รายการ
                        </label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-3"></div>
                  </div>
                </div>
                
              </div> -->
              <!-- Table -->
              <div class="table-responsive mt-4">
                <table class="table">
                  <thead class="thead-light">
                    <tr>
                      <th>ลำดับ</th>
                      <th>ชื่อลูกค้า</th>
                      <th>เบอร์โทร</th>
                      <th>ทะเบียนรถ</th>
                      <th>ยี่ห้อ</th>
                      <th>รุ่น</th>

                      <th></th>
                    </tr>
                  </thead>
                  <tbody v-if="this.contactMessge.length > 0">
                    <tr v-for="(item, index) in contactMessge" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td scope="row">
                        {{ item.name }}
                      </td>
                      <td>{{ item.phone }}</td>

                      <td>
                        {{ item.license }}
                      </td>
                      <td>
                        {{ item.BrandTh }}
                      </td>
                      <td>
                        {{ item.ModelTh }}
                      </td>

                      <td>
                        <ul class="list-inline mb-0 action">
                          <li class="list-inline-item">
                            <a
                              class="px-2 text-danger"
                              v-b-tooltip.hover
                              title="Delete"
                              @click="ConfirmDel(index)"
                            >
                              <i class="uil uil-trash-alt font-size-18"></i>
                            </a>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="row p-4">
                  <span
                    v-if="this.contactMessge.length === 0"
                    class="text-center font-size-16"
                    >ยังไม่เพิ่มรายการ</span
                  >
                  <div class="col-md-6 ml-4">
                    <b-button
                      type="button"
                      variant="info"
                      size="sm"
                      v-b-modal.modalAddContact
                    >
                      <i class="mdi mdi-plus me-1"></i>เพิ่มรายชื่อติดต่อใหม่
                    </b-button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- modal ลูกค้า-->
    <b-modal
      ref="modalContact"
      id="modalContact"
      title="รายชื่อลูกค้า"
      hide-footer
      size="lg"
      centered
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-12 align-items-center">
            <div class="row align-items-center">
              <div class="col-12 col-sm-4 col-md-4">
                <label class="d-inline align-items-center m-2 text-end">
                  รหัสลูกค้า:
                  <b-form-input
                    v-model="filter.cusId"
                    type="search"
                    placeholder="รหัสลูกค้า"
                    class="form-control form-control ms-2"
                    @keyup.enter="handleSearch"
                  ></b-form-input>
                </label>
              </div>
              <div class="col-12 col-sm-4 col-md-4">
                <label class="d-inline align-items-center m-2 text-end">
                  ชื่อจริง:
                  <b-form-input
                    v-model="filter.nameTh"
                    type="search"
                    placeholder="ชื่อจริง/ชื่อนิติบุคคล"
                    class="form-control form-control ms-2"
                    @keyup.enter="handleSearch"
                  ></b-form-input>
                </label>
              </div>
              <div class="col-12 col-sm-4 col-md-4">
                <label class="d-inline align-items-center m-2">
                  นามสกุล:
                  <b-form-input
                    v-model="filter.familyNameTh"
                    type="search"
                    placeholder="นามสกุล"
                    class="form-control form-control ms-2"
                    @keyup.enter="handleSearch"
                  ></b-form-input>
                </label>
              </div>
              <div class="col-12 col-sm-4 col-md-4">
                <label class="d-inline align-items-center m-2">
                  เบอร์โทรศัพท์:
                  <b-form-input
                    v-model="filter.mobilePhone"
                    type="search"
                    placeholder="เบอร์โทรศัพท์"
                    class="form-control form-control ms-2"
                    @keyup.enter="handleSearch"
                  ></b-form-input>
                </label>
              </div>
              <div
                class="col-2 col-sm-2 col-md-2 text-end"
                style="margin-left: auto; margin-right: 0"
              >
                <b-button
                  class="btn btn ms-2"
                  variant="info"
                  type="submit"
                  @click="handleSearch"
                >
                  <i class="uil-search"></i>
                  ค้นหา
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton-table
              :rows="5"
              :columns="6"
              :table-props="{ bordered: false, striped: true }"
              animation="throb"
            ></b-skeleton-table>
          </template>
          <div class="row mt-2">
            <!-- Search -->
            <div class="col-sm-12 col-md-12">
              <div class="row align-items-center">
                <div class="col-sm-12 col-md-3">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-block align-items-center">
                      แสดงผล
                      <b-form-select
                        v-model="perPageCus"
                        size="sm"
                        :options="pageOptionsCus"
                        @input="handlePageChangeCus"
                      ></b-form-select
                      >&nbsp; รายการ
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->

          <div class="table-responsive mb-0">
            <b-table
              :items="rowCus"
              :fields="fieldsCus"
              responsive="true"
              :per-page="perPageCus"
              :current-page="1"
              :sort-by.sync="sortBy2"
              :sort-desc.sync="sortDesc2"
              :filter="filter"
              :filter-included-fields="filterOn2"
              ref="selectableTableCus"
              selectable
              @row-selected="onRowSelected"
            >
              <template #cell(selected)="{ rowSelected }">
                <template v-if="rowSelected">
                  <span aria-hidden="true">&check;</span>
                  <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                  <span aria-hidden="true">&nbsp;</span>
                  <span class="sr-only">Not selected</span>
                </template>
              </template>

              <!-- End edit table -->
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              หน้า {{ currentPage }} จาก {{ this.totalPageCus }} ทั้งหมด
              {{ totalRecordCus }} รายการ
            </div>

            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPageCus"
                    :total-rows="totalRecordCus"
                    :per-page="perPageCus"
                    @change="handleChangePageCus"
                  ></b-pagination>
                </ul>
              </div>
            </div>

            <!-- <p>
              <b-button size="sm" @click="selectAllRowsCus"
                >เลือกทั้งหมด</b-button
              >
              |
              <b-button size="sm" @click="clearSelectedCus"
                >ล้างการเลือก</b-button
              >
            </p> -->
          </div>
          <!-- </div> -->
        </b-skeleton-wrapper>
      </div>

      <div class="row">
        <div class="col-md">
          <button class="btn btn-success float-end" @click="closeCus">
            ตกลง
          </button>
        </div>
      </div>
    </b-modal>

    <!-- modal รถ -->
    <b-modal
      ref="modalCar"
      id="modalCar"
      title="รายชื่อรถ"
      hide-footer
      size="xl"
      centered
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-12 align-items-center">
            <div class="row align-items-center">
              <div class="col-12 col-sm-4 col-md-4">
                <label class="d-inline align-items-center m-2 text-end">
                  เลขตัวถัง:
                  <b-form-input
                    v-model="filterVeh.vin"
                    type="search"
                    placeholder="เลขตัวถัง"
                    class="form-control form-control ms-2"
                    @keyup.enter="handleSearchVeh"
                  ></b-form-input>
                </label>
              </div>
              <!-- <div class="col-12 col-sm-4 col-md-4">
                <label class="d-inline align-items-center m-2 text-end">
                  ผู้ขับขี่:
                  <b-form-input
                    v-model="filterVeh.driverNameTh"
                    type="search"
                    placeholder="ชื่อผู้ขับขี่"
                    class="form-control form-control ms-2"
                    @keyup.enter="handleSearchVeh"
                  ></b-form-input>
                </label>
              </div> -->
              <div class="col-12 col-sm-4 col-md-4">
                <label class="d-inline align-items-center m-2">
                  ทะเบียนรถ:
                  <b-form-input
                    v-model="filterVeh.licensePlate"
                    type="search"
                    placeholder="ทะเบียนรถ"
                    class="form-control form-control ms-2"
                    @keyup.enter="handleSearchVeh"
                  ></b-form-input>
                </label>
              </div>
              <!-- <div class="col-12 col-sm-4 col-md-4">
                <label class="d-inline align-items-center m-2">
                  เจ้าของรถ:
                  <b-form-input
                    v-model="filterVeh.ownerNameTh"
                    type="search"
                    placeholder="เจ้าของรถ"
                    class="form-control form-control ms-2"
                    @keyup.enter="handleSearchVeh"
                  ></b-form-input>
                </label>
              </div> -->
              <div
                class="col-2 col-sm-2 col-md-2 text-end"
                style="margin-left: auto; margin-right: 0"
              >
                <b-button
                  class="btn btn ms-2"
                  variant="info"
                  type="submit"
                  @click="handleSearchVeh"
                >
                  <i class="uil-search"></i>
                  ค้นหา
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton-table
              :rows="5"
              :columns="6"
              :table-props="{ bordered: false, striped: true }"
              animation="throb"
            ></b-skeleton-table>
          </template>
          <div class="row mt-2">
            <!-- Search -->
            <div class="col-sm-12 col-md-12">
              <div class="row align-items-center">
                <div class="col-sm-5 col-md-3">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-block align-items-center">
                      แสดงผล
                      <b-form-select
                        v-model="perPageVeh"
                        size="sm"
                        :options="pageOptionsVeh"
                        @input="handlePageChangeVeh"
                      ></b-form-select
                      >&nbsp; รายการ
                    </label>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6"></div>
                <!-- <div class="col-md-3 col-sm-7">
                  <div class="mb-3 position-relative">
                    <label for="veh"
                      >เลือก<code>(เจ้าของรถ/ผู้ขับขี่)</code></label
                    >
                    <multiselect
                      id="veh"
                      v-model="veh"
                      :options="optionfieldVeh"
                      track-by="name"
                      label="name"
                      :show-labels="false"
                      @input="showFieldVeh"
                    ></multiselect>
                  </div>
                </div> -->
              </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->
          <!-- เจ้าของรถ -->
          <!--  v-if="this.veh.name === 'เจ้าของรถ'" -->
          <div class="table-responsive mb-0">
            <b-table
              :items="rowVehicles"
              :fields="fieldsVehicle"
              responsive="true"
              :per-page="perPageVeh"
              :current-page="1"
              :sort-by.sync="sortByVeh"
              :sort-desc.sync="sortDescVeh"
              :filter="filterVeh"
              :filter-included-fields="filterOnVeh"
              ref="selectableTableVeh"
            >
              <template v-slot:cell(ownerMobilePhone)="rowVehicles">
                <b-button
                  v-if="rowVehicles.item.ownerMobilePhone !== null"
                  variant="light"
                  v-b-tooltip.hover
                  class="px-2"
                  selectable
                  @click="selectOwner(rowVehicles.item, 1)"
                >
                  {{ rowVehicles.item.ownerMobilePhone }}
                </b-button>
              </template>

              <template v-slot:cell(driverMobilePhone)="rowVehicles">
                <b-button
                  v-if="rowVehicles.item.driverMobilePhone !== null"
                  class="px-2"
                  variant="light"
                  v-b-tooltip.hover
                  @click="selectOwner(rowVehicles.item, 2)"
                >
                  {{ rowVehicles.item.driverMobilePhone }}
                </b-button>
              </template>
              <template #cell(selected)="{ rowSelected }">
                <template v-if="rowSelected">
                  <span aria-hidden="true">&check;</span>
                  <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                  <span aria-hidden="true">&nbsp;</span>
                  <span class="sr-only">Not selected</span>
                </template>
              </template>

              <!-- End edit table -->
            </b-table>
          </div>

          <div class="row">
            <div class="col">
              หน้า {{ currentPage }} จาก {{ this.totalPageVehicles }} ทั้งหมด
              {{ totalRecordVeh }} รายการ
            </div>

            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPageVeh"
                    :total-rows="totalRecordVeh"
                    :per-page="perPageVeh"
                    @change="handleChangePageVeh"
                  ></b-pagination>
                </ul>
              </div>
            </div>
            <br />
            <hr />
            <br />
            <!-- <p>
              <b-button size="sm" @click="selectAllRowsVeh"
                >เลือกทั้งหมด</b-button
              >
              |
              <b-button size="sm" @click="clearSelectedVeh"
                >ล้างการเลือก</b-button
              >
            </p> -->
          </div>
          <!-- </div> -->
        </b-skeleton-wrapper>
      </div>

      <div class="row">
        <div class="col-md">
          <button class="btn btn-success float-end" @click="closeCar">
            ตกลง
          </button>
        </div>
      </div>
    </b-modal>

    <b-modal
      ref="modalAddContact"
      id="modalAddContact"
      title="เพิ่มรายชื่อติดต่อใหม่"
      hide-footer
      size="lg"
      centered
    >
      <div class="card-body">
        <!-- <form class="needs-validation" @submit.prevent="formAdd"> -->
        <div class="row">
          <div class="col-md-4 col-sm-6">
            <div class="mb-3 position-relative">
              <label for="name">ชื่อลูกค้า:</label>
              <input
                id="name"
                v-model="name"
                type="text"
                class="form-control"
                @keyup.enter="addModal"
                value="Otto"
              
              />
            </div>
          </div>
          <div class="col-md-4 col-sm-6">
            <div class="mb-3 position-relative">
              <code>* </code><label for="phone">เบอร์โทร:</label>
              <input
                id="phone"
                v-model="phone"
                type="tel"
                maxlength="10"
                class="form-control input-placeholder"
                @keyup.enter="addModal"
                placeholder="เบอร์โทรศัพท์แบบไม่มีขีด 10 หลัก"
              />
            </div>
          </div>
          <div class="col-md-4 col-sm-6">
            <div class="mb-3 position-relative">
              <label for="license">ทะเบียนรถ:</label>
              <input
                id="license"
                v-model="license"
                type="text"
                class="form-control"
                @keyup.enter="addModal"
                
              />
            </div>
          </div>
          <div class="col-md-5 col-sm-6">
            <div class="mb-3 position-relative">
              <label for="BrandTh">ยี่ห้อ:</label>
              <input
                id="BrandTh"
                v-model="BrandTh"
                type="text"
                class="form-control"
                @keyup.enter="addModal"
               
              />
            </div>
          </div>
          <div class="col-md-5 col-sm-6">
            <div class="mb-3 position-relative">
              <label for="ModelTh">รุ่น:</label>
              <input
                id="ModelTh"
                v-model="ModelTh"
                type="text"
                class="form-control"
                @keyup.enter="addModal"
                
              />
            </div>
          </div>

          <div class="row">
            <div class="col-md">
              <button class="btn btn-success float-end" @click="addModal">
                บันทึก</button
              >&nbsp;&nbsp;&nbsp;

              <button class="btn btn-danger float-end" @click="hide">
                ปิด
              </button>
            </div>
          </div>
        </div>
        <!-- </form> -->
      </div>

      <div class="row"></div>
    </b-modal>
  </Layout>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import { _ } from "vue-underscore";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: appConfig.groupContacts,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  data() {
    return {
      title: "สร้างการติดต่อลูกค้า",
      items: [
        {
          text: "ลูกค้าสัมพันธ์",
          active: true,
        },
        {
          text: appConfig.groupContacts,
          href: "/contactMessage",
        },
        {
          text: "สร้างการติดต่อลูกค้า",
          active: true,
        },
      ],
      localDataBranch: [], //เป็น-array Id
      localData: [],

      message: "โปรดตรวจสอบข้อมูล",
      CusType: "",
      CustypeName: "",
      cusId: this.$route.params.cusId,
      filter: [
        {
          nameTh: "",
          familyNameTh: "",
          mobilePhone: "",
          cusId: "",
        },
      ],
      loading: false,
      total_pages: "",
      page: "",
      per_page: "",
      totalRecord: "",
      rowCus: [],
      selectArray: [],
      optionfieldVeh: [
        {
          name: "เจ้าของรถ",
          id: 1,
        },
        {
          name: "ผู้ขับขี่",
          id: 2,
        },
      ],
      veh: "",
      // ? ตารางที่ถูกเลือก

      name: "",
      phone: "",
      liselicensence: "",
      BrandTh: "",
      ModelTh: "",
      dataVehicles: [],
      license: "",
      arrCusId: [],
      arrPhone: [],
      arrVehId: [],
      phoneArray: [],
      cusTo: [],
      nameTh: "",
      familyNameTh: "",
      mobilePhone: "",
      licensePlate: "",
      localDataBranchName: [],
      selectModal: [],
      currentPage: 1,
      perPage: 30,
      pageOptions: [10, 25, 50, 100],
      filterOn: [],
      from: "",
      sortBy: "age",
      sortDesc: false,
      selectMode: "multi",
      contact: {
        branchId: "",
        contactType: {
          nameTh: "SMS",
        },
        subject: "",
        message: "",
        filterType: "",
        templateId: "",
        vehicleId: "",
        cusId: "",
        mobilePhone: "",
      },
      selected: [],
      contactMessge: [],
      submitform: false,
      submit: false,
      optionContactType: [
        {
          nameTh: "SMS",
        },
        { nameTh: "EMAIL" },
      ],
      totalPageCus: "",
      optionFilterType: [{ name: "MANUAL" }, { name: "COND" }],
      // ? ตารางที่เลือก modal  api customer
      totalRecordCus: "",
      currentPageCus: 1,
      perPageCus: 10,
      pageOptionsCus: [10, 25, 50, 100],
      filterOn2: [],
      sortBy2: "age",
      sortDesc2: false,
      selectMode2: "multi",
      cusArr: [],

      selectedUsers: [],
      isSelectedAll: false,

      fieldsCus: [
        {
          key: "cusId",
          label: "รหัสลูกค้า",
        },

        {
          key: "nameTh",
          sortable: true,
          label: "ชื่อ",
        },
        {
          key: "familyNameTh",
          sortable: true,
          label: "นามสกุล",
        },
        {
          key: "mobilePhone",
          sortable: true,
          label: "โทรศัพท์",
        },
        {
          key: "email",
          sortable: true,
          label: "อีเมล",
        },
      ],

      // ? ตารางที่เลือก modal  api vehicle
      color: "white",
      myToggle: false,
      isActive: false,
      filterVeh: [
        {
          vin: "",

          licensePlate: "",
        },
      ],
      totalPageVehicles: "",
      arr: [],
      totalRecordVeh: "",
      rowVehicles: [],
      currentPageVeh: 1,
      perPageVeh: 10,
      pageOptionsVeh: [10, 25, 50, 100],
      filterOnVeh: [],
      sortByVeh: "age",
      sortDescVeh: false,
      selectModeVeh: "multi",
      fieldsVehicle: [
        {
          key: "ownerNameTh",
          sortable: true,
          label: "เจ้าของรถ",
        },
        {
          key: "ownerMobilePhone",
          label: "เบอร์(เจ้าของรถ)",
        },
        {
          key: "driverNameTh",
          sortable: true,
          label: "ผู้ขับขี่",
        },
        {
          key: "driverMobilePhone",
          label: "เบอร์(ผู้ขับขี่)",
        },

        {
          key: "licensePlate",
          sortable: true,
          label: "ทะเบียนรถ",
        },
        {
          key: "vehicleBrandTh",
          sortable: true,
          label: "ยี่ห้อ",
        },
      ],

      filterOnVeh2: [],
      sortByVeh2: "age",
      sortDescVeh2: false,
      selectModeVeh2: "multi",
      fieldsVehicle2: [
        {
          key: "driverNameTh",
          sortable: true,
          label: "ผู้ขับขี่",
        },
        {
          key: "driverMobilePhone",
          label: "เบอร์โทร",
        },
        {
          key: "licensePlate",
          sortable: true,
          label: "ทะเบียนรถ",
        },
        {
          key: "vehicleBrandTh",
          sortable: true,
          label: "ยี่ห้อ",
        },
      ],
    };
  },
  validations: {
    contact: {
      branchId: { required },
      contactType: { required },
      subject: { required },
      message: { required },
      // mobilePhone: { required },
    },
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.rowData.length;
    },
    //
  },
  mounted() {
    // Set the initial number of items

  },

  created() {
    // this.selected = JSON.parse(localStorage.getItem("selected1"));
    this.getLocalData();
    this.getCus();
    this.getGroupContact();
    // this.customer();
    // console.log("888", this.customer);
    this.getVehicles();
    // CustypeChange();
    this.startIndex = 1;
    this.endIndex = this.perPage;
  },
  methods: {
    addModal() {
      var name = this.name;
      var phone = this.phone;
      var license = this.license;
      var BrandTh = this.BrandTh;
      var ModelTh = this.ModelTh;
      if (phone != "") {

          this.contactMessge.push({
            name: name ? name : "",
            phone: phone ? phone : "",
            license: license ? license : "",
            BrandTh: BrandTh ? BrandTh : "",
            ModelTh: ModelTh ? ModelTh : "",
          });
          this.$refs["modalAddContact"].hide();
          this.contactMessge;
          this.name = "";
          this.phone = "";
          this.license = "";
          this.BrandTh = "";
          this.ModelTh = "";

      } else {
        Swal.fire(
          appConfig.swal.title.error,
          "กรุณากรอกเบอร์โทร",
          appConfig.swal.type.error
        );
      }
    },
    contactForm() {
      // // this.postUser();
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.contact.$invalid != true) {
        this.postContact();
      }
    },
    addTable() {
      this.select.push({
        vehicleId: "",
        cusId: "",
        mobilePhone: "",
      });
    },
    hide() {
      this.$refs["modalAddContact"].hide();
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      const localDataBranch = [];
      const localDataBranchName = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
        localDataBranchName.push(item);
      });
      this.localDataBranchName = localDataBranchName;
      this.localDataBranch = localDataBranch;
    },
    getCus: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/group-contact/customer", {
          params: {
            page: this.currentPageCus,
            perPage: this.perPageCus,
            branchId: this.localDataBranch,
            nameTh: this.filter.nameTh,
            familyNameTh: this.filter.familyNameTh,
            mobilePhone: this.filter.mobilePhone,
            cusId: this.filter.cusId,
          },
        })
        .then((response) => {
          this.rowCus = response.data.data;
          const per_Page = response.data.perPage;
          this.from = response.data.from;
          this.totalRecordCus = response.data.total;
          this.to = response.data.to;
          this.totalPageCus = Math.ceil(this.totalRecordCus / per_Page);
          // console.log(response);
          this.paginatedData = this.rowCus.slice(
            this.startIndex,
            this.endIndex
          );
          // );
          // this.CustypeChange();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getGroupContact: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/group-contact", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.localDataBranch,
          },
        })
        .then((response) => {
          this.rowGroupContact = response.data.data;
          this.from = response.data.from;
          this.totalRecordVeh = response.data.total;
          this.to = response.data.to;

          // console.log("Group", response);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getGroupContactShow: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/group-contact/show", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.localDataBranch,
          },
        })
        .then((response) => {
          this.rowGroupContact = response.data.data;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;

          // console.log("Group", response);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getVehicles: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/group-contact/vehicle", {
          params: {
            page: this.currentPageVeh,
            perPage: this.perPageVeh,
            branchId: this.localDataBranch,
            vin: this.filterVeh.vin,
            driverNameTh: this.filterVeh.driverNameTh,
            licensePlate: this.filterVeh.licensePlate,
            ownerNameTh: this.filterVeh.ownerNameTh,
          },
        })
        .then((response) => {
          this.rowVehicles = response.data.data;
          const per_Page = response.data.perPage;
          this.from = response.data.from;
          this.totalRecordVeh = response.data.total;
          this.to = response.data.to;
          this.totalPageVehicles = Math.ceil(
            this.totalRecordVeh / per_Page
          );
          // console.log("6666", response);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    postContact: function () {
      this.overlayFlag = true;

      const cusArrId = [];
      const vehArrId = [];
      const phoneArr = [];
      this.contactMessge.forEach((item) => {
        if (item.vehicleId != null && item.cusId != null) {
          phoneArr.push(item.phone);
          cusArrId.push(item.cusId);
          vehArrId.push(item.vehicleId);
        } else {
          phoneArr.push(item.phone ? item.phone : 0);
          cusArrId.push(item.cusId ? item.cusId : 0);
          vehArrId.push(item.vehicleId ? item.vehicleId : 0);
        }
      });
      this.vehArrId = vehArrId;
      this.cusArrId = cusArrId;
      this.phoneArr = phoneArr;
      // console.log(this.phoneArr, "111111");
      // console.log(this.vehArrId, this.cusArrId, "vehArrvehArrvehArr");

      useNetw
        .post("api/group-contact/store", {
          branchId: this.contact.branchId.branchId,
          contactType: this.contact.contactType.nameTh,
          subject: this.contact.subject,
          message: this.contact.message,
          filterType: "MANUAL",
          templateId: "",
          vehicleId: this.vehArrId,
          cusId: this.cusArrId,
          mobilePhone: this.phoneArr,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.contactMessge;
          this.$router.push({ name: "contactMessage" });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    handleSearch() {
      this.getCus();
    },
    handleSearchVeh() {
      this.getVehicles();
    },

    ConfirmDel(index) {
      Swal.fire({
        title: "ต้องการลบรายการนี้ใช่หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "ลบ",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.value) {
          this.contactMessge.splice(index, 1);
          this.arr.splice(index, 1);
          this.cusArr.splice(index, 1);
        }
      });
    },
    deleteCus: function (rowCus) {
      this.userData = rowCus;
      this.loading = true; //skeleton true
      useNetw
        .delete("api/customer/delete", {
          data: {
            cusId: this.userData,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
          this.getCus();
        });
    },
    // ! CUSTOMER
    handleChangePageCus(page) {
      this.currentPageCus = page;
      this.getCus();
    },

    handlePageChangeCus(active) {
      this.perPageCus = active;
      this.currentPageCus = 1;
      this.getCus();
    },
    // !ถูกเลือก
    handleChangePageMess(page) {
      this.currentPage = page;
      this.getCus();
    },
    handlePageChangeMess(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getCus();
    },
    // ! รถ
    handleChangePageVeh(page) {
      this.currentPageVeh = page;
      this.getVehicles();
    },
    handlePageChangeVeh(active) {
      this.perPageVeh = active;
      this.currentPageVeh = 1;
      this.getVehicles();
    },
    showFieldVeh() {
      if (this.veh.name === "ผู้ขับขี่") {
        this.getVehicles();
        // this.fieldsVehicle2;
      } else {
        this.getVehicles();
        // this.fieldsVehicle;
      }
      // console.log(this.fieldsVehicle[2]);
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.rowCus = filteredItems.length;
      this.currentPage = 1;
    },

    onRowSelected(rowCus) {
      for (let index = 0; index < rowCus.length; index++) {
        const cus = [];

        if (rowCus[index].mobilePhone != null) {
          cus["cusId"] = rowCus[index].cusId;
          cus["name"] =
            `${rowCus[index].nameTh != null ? rowCus[index].nameTh : ""}` +
            " " +
            `${
              rowCus[index].familyNameTh != null
                ? rowCus[index].familyNameTh
                : ""
            }`;
          cus["phone"] = rowCus[index].mobilePhone;

          this.cusArr = rowCus;
          this.name = cus["name"];
          this.phone = cus["phone"];
          this.cusId = cus["cusId"];
          var uniqe = _.findWhere(this.contactMessge, cus); // เลือกซ้ำ

          if (uniqe == null) {
            this.contactMessge.push(cus);
          }
        } else {
          Swal.fire(
            appConfig.swal.title.error,
            "กรุณาระบุเบอร์โทร",
            appConfig.swal.type.error
          );
          this.getCus();
        }

        // console.log(cusArr,phoneArr, "");
      }

      // console.log(this.contactMessge, "0000000000");
      // localStorage.setItem("selected1", JSON.stringify(this.selected));
    },

    // onRowRemove(index) {
    //   this.customer.splice(index, 1);
    //   this.customer
    //   // localStorage.setItem("selected", JSON.stringify(this.selected));
    // },

    selectAllRowsCus() {
      this.$refs.selectableTableCus.selectAllRowsCus();
    },
    clearSelectedCus() {
      this.$refs.selectableTableCus.clearSelectedCus();
    },
    selectAllRowsVeh() {
      this.$refs.selectableTableVeh.selectAllRowsVeh();
    },
    clearSelectedVeh() {
      this.$refs.selectableTableVeh.clearSelectedVeh();
    },
    closeCus() {
      this.$refs["modalContact"].hide();
      this.contactMessge;
    },
    closeCar() {
      this.$refs["modalCar"].hide();
      this.contactMessge;
    },
    selectOwner(data, id) {
      this.vehIdArr = data;
      this.arr.push(data);

      if (id === 1) {
        // eslint-disable-next-line no-redeclare

        var cus = [];
        // cus["vehicleId"] = rowVehicles;
        cus["vehicleId"] = data.vehicleId;
        cus["name"] = data.ownerNameTh;
        cus["phone"] = data.ownerMobilePhone;
        cus["license"] = data.licensePlate;
        cus["BrandTh"] = data.vehicleBrandTh;
        cus["ModelTh"] = data.vehicleModelTh;
        this.vehicleId = cus["vehicleId"];
        this.name = cus["name"];
        this.phone = cus["phone"];
        this.license = cus["license"];
        this.BrandTh = cus["BrandTh"];
        this.ModelTh = cus["ModelTh"];
        // this.vehIdArr = cus["vehicleId"];

        // eslint-disable-next-line no-redeclare
        var uniqe = _.findWhere(this.contactMessge, cus);

        if (uniqe == null) {
          this.contactMessge.push(cus);
        } else {
          Swal.fire(
            appConfig.swal.title.error,
            "เบอร์ซ้ำ",
            appConfig.swal.type.error
          );
        }
        // console.log("เจ้าของ", this.contactMessge);
      }
      if (id === 2) {
        // eslint-disable-next-line no-redeclare
        var cus = [];
        cus["vehicleId"] = data.vehicleId;
        cus["name"] = data.driverNameTh;
        cus["phone"] = data.driverMobilePhone;
        cus["license"] = data.licensePlate;
        cus["BrandTh"] = data.vehicleBrandTh;
        cus["ModelTh"] = data.vehicleModelTh;

        this.name = cus["name"];
        this.phone = cus["phone"];
        this.license = cus["license"];
        this.BrandTh = cus["BrandTh"];
        this.ModelTh = cus["ModelTh"];
        this.vehicleId = cus["vehicleId"];

        // eslint-disable-next-line no-redeclare
        var uniqe = _.findWhere(this.contactMessge, cus);

        if (uniqe == null) {
          this.contactMessge.push(cus);
        } else {
          Swal.fire(
            appConfig.swal.title.error,
            "เบอร์ซ้ำ",
            appConfig.swal.type.error
          );
        }
        // console.log("ผู้ขับขี่", this.contactMessge);
      }
    },
  },
  middleware: "authentication",
};
</script>
<style scoped>
.activeColor {
  background: rgb(255, 255, 255) !important;
}
.white {
  background-color: white;
}
.blue {
  background-color: rgb(255, 131, 100);
}
.input-placeholder {
  font-size: 12px;
}
</style>
